import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import HeroSlider from '../components/hero/index.js'

const ThankyouPage = () => (
	<Layout head={{ title: 'Thank You' }}>
		<div id="hero">
			<HeroSlider />
		</div>
		<section>
			<div className="container">
				<div className="row">
					<div className="col">
						<h2 className="section-title">
							THANK YOU FOR SENDING YOUR REQUEST. WE WILL BE IN CONTACT WITH YOU
							VERY SOON.
						</h2>
						<p className="section-p">
							NOTE: If you can’t find it in the inbox check on the spam folder.
						</p>
						<div className="rtn-btn mt-4">
							<Link to="/" className="return-btn">
								RETURN TO HOMEPAGE
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
)

export default ThankyouPage
