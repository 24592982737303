import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
// import hero01 from '../../images/herobg-01-min.jpg'
// import hero02 from '../../images/herobg-02-min.jpg'
// import hero03 from '../../images/herobg-03-min.jpg'
// import hero04 from '../../images/herobg-04-min.jpg'
import hero05 from '../../images/herobg-05-min.jpg'
import hero06 from '../../images/herobg-06-min.jpg'
import hero07 from '../../images/herobg-07-min.jpg'
// import hero08 from '../../images/herobg-08-min.jpg'
// import hero09 from '../../images/herobg-09-min.jpg'
// import hero10 from '../../images/herobg-10-min.jpg'
// import hero11 from '../../images/herobg-11-min.jpg'
// import hero12 from '../../images/herobg-12-min.jpg'
// import hero13 from '../../images/herobg-13-min.jpg'
import hero14 from '../../images/herobg-14-min.jpg'
// import hero15 from '../../images/herobg-15-min.jpg'
// import hero16 from '../../images/herobg-16-min.jpg'
// import hero17 from '../../images/herobg-17-min.jpg'

export default class Navigation extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={hero05} className="img-fluid" alt="Header Slider 5" />
				</div>
				<div>
					<img src={hero06} className="img-fluid" alt="Header Slider 6" />
				</div>
				<div>
					<img src={hero07} className="img-fluid" alt="Header Slider 7" />
				</div>

				<div>
					<img src={hero14} className="img-fluid" alt="Header Slider 14" />
				</div>

				{/*
					<div>
					<img src={hero08} className="img-fluid" alt="Header Slider 8" />
				</div>
				<div>
					<img src={hero10} className="img-fluid" alt="Header Slider 10" />
				</div>
				<div>
					<img src={hero11} className="img-fluid" alt="Header Slider 11" />
				</div>
					<div>
					<img src={hero13} className="img-fluid" alt="Header Slider 13" />
				</div>
				<div>
					<img src={hero15} className="img-fluid" alt="Header Slider 15" />
				</div><div>
          <img src={hero17} className="img-fluid" alt="Header Slider 17" />
        </div>*/}
			</Swiper>
		)
	}
}
